import React from "react";
import AboutUs from "../components/AboutUs";
import Breadcrump from "../components/Breadcrump";
import Registration from "../components/Registration";
import Testimonial from "../components/Testimonial";
const About = () => {
  return (
    <div className="about-section">
      <Breadcrump title={'من نكون'}/>
      <AboutUs />
      <Registration />
      <Testimonial />
    </div>
  );
};
export default About;
