import React from "react";
import Courses from "../components/Courses";
import Breadcrump from "../components/Breadcrump";
import Category from "../components/Category";
const TheCourses = () => {
  return (
    <div className="theCourses-section">
      <Breadcrump title={'الدورات'}/>
      <Category />
      <Courses />
    </div>
      );
}
export default TheCourses;