import React from "react";
import ContactUs from "../components/ContactUs";
import Breadcrump from "../components/Breadcrump";
const Contact = () => {
  return(
    <div className="home-section">
      <Breadcrump title={'اتصال'}/>
      <ContactUs />
    </div>
  );
}
export default Contact;