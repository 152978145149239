import helal from '../assets/helal.jpg'
const SecondorySchool = () => {
  return (
    <div className="secondarySchool">
      <br />
      <div className = "container">
       <div className="row">
        <div className="col-md-6">
       <img className="img-fluid w-100" 
       src={helal} alt="This is img of Mr/helal"/>
        </div>
        <div className="col-md-4">
         <h1 style={{color:"blue"}}>مدير الأكاديمية</h1>
         <h1> الأستاذ/هلال الطحان </h1> <br />
         <ul>
          <li> حاصل علي ليسانس لغة عربية جامعة القاهرة</li>
          <li> مدير مدرسة المتانيا الثانوية المشتركة</li> 
          <li>موجه لغة عربية لدي ادارة العياط التعليمية</li> 
          <li>خبرة أكثر من 30 سنة في تدريس اللغة العربية</li> 
         </ul>
        </div>
       </div>
      </div>
    </div>
      );
}
export default SecondorySchool;