import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Header.module.css";
import "./Header.css";
import logo from "../assets/logo.png";

const Header = () => (
  <>
    <header className={styles.header}>
      {/* Uncomment and update with Link components if needed */}
      {/* <nav>
        <Link to="/Home">Home</Link>
        <Link to="/Courses">Courses</Link>
        <Link to="/About">About Us</Link>
        <Link to="/Contact">Contact Us</Link>
      </nav> */}
    </header>

    <div className="container-fluid d-none d-lg-block">
      <div className="row align-items-center py-4 px-xl-5">
        <div className="col-lg-3">
          <a href="/" className="text-decoration-none">
            <h1 className="m-0">
              <img
                src={logo}
                height="100px"
                alt="This the logo of the academy"
              />
            </h1>
          </a>
        </div>
        <div className="col-lg-3 text-right">
          <div className="d-inline-flex align-items-center justify-content-start">
            <i className="fa fa-2x fa-map-marker-alt text-primary mx-2"></i>
            <div className="text-right">
              <h6 className="font-weight-semi-bold mb-1">العنوان</h6>
              <small className="text-right">
                عمارة بنك مصر -الدور الثاني - طريق مصر أسيوط السريع
              </small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 text-right">
          <div className="d-inline-flex align-items-center">
            <i className="fa fa-2x fa-envelope text-primary mx-2"></i>
            <div className="text-right">
              <h6 className="font-weight-semi-bold mb-1">الايميل</h6>
              <small>azab.group@yahoo.com</small>
            </div>
          </div>
        </div>
        <div className="col-lg-3 text-right">
          <div className="d-inline-flex align-items-center">
            <i className="fa fa-2x fa-phone text-primary mr-3"></i>
            <div className="text-left">
              <h6 className="font-weight-semi-bold mb-1" align="right">
                اتصل بنا
              </h6>
              <small style={{ padding: "15px" }}>01128181829</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="container-fluid">
      <div className="row border-top px-xl-5">
        <div className="col-lg-3 d-none d-lg-block">
          <a
            className="d-flex align-items-center justify-content-between bg-secondary w-100 text-decoration-none"
            data-toggle="collapse"
            href="#navbar-vertical"
            style={{ height: "67px", padding: "0 30px" }}
          >
            <h5 className="text-primary m-0">
              <i className="fa fa-book-open mr-2"></i>المواد الدراسية
            </h5>
            <i className="fa fa-angle-down text-primary"></i>
          </a>
          <nav
            className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 border border-top-0 border-bottom-0 bg-light"
            id="navbar-vertical"
            style={{ width: "calc(100% - 30px)", zIndex: 9 }}
          >
            <div className="navbar-nav w-100">
              <div className="nav-item dropdown">
                <a href="/" className="nav-link" data-toggle="dropdown">
                  Web Design{" "}
                  <i className="fa fa-angle-down float-right mt-1"></i>
                </a>
                <div className="dropdown-menu position-absolute bg-secondary border-0 rounded-0 w-100 m-0">
                  <a href="/" className="dropdown-item">
                    HTML
                  </a>
                  <a href="/" className="dropdown-item">
                    CSS
                  </a>
                  <a href="/" className="dropdown-item">
                    jQuery
                  </a>
                </div>
              </div>
              <a href="/" className="nav-item nav-link">
                Apps Design
              </a>
              <a href="/" className="nav-item nav-link">
                Marketing
              </a>
              <a href="/" className="nav-item nav-link">
                Research
              </a>
              <a href="/" className="nav-item nav-link">
                SEO
              </a>
            </div>
          </nav>
        </div>
        <div className="col-lg-9">
          <nav className="navbar navbar-expand-lg bg-light navbar-light py-3 py-lg-0 px-0">
            <a href="/" className="text-decoration-none d-block d-lg-none">
              <h1 className="m-0">
                <span className="text-primary">E</span>COURSES
              </h1>
            </a>
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarCollapse"
            >
              <div className="navbar-nav py-0">
                <NavLink
                  to="/"
                  className="nav-item nav-link"
                  activeClassName="active"
                  exact
                >
                  الصفحة الرئيسية
                </NavLink>
                <NavLink
                  to="/about"
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  نبذة عنا
                </NavLink>
                <NavLink
                  to="/courses"
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  الدورات
                </NavLink>
                <NavLink
                  to="/teachers"
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  المعلمين
                </NavLink>
                <NavLink
                  to="/contact"
                  className="nav-item nav-link"
                  activeClassName="active"
                >
                  تواصل معنا
                </NavLink>
              </div>
              <a
                className="btn btn-primary py-2 px-4 ml-auto d-none d-lg-block"
                href="/"
              >
                انضم الان
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </>
);

export default Header;
