import React from "react";
import { Carousel } from "react-bootstrap";

const CustomCarousel = () => {
  return (
    <div className="container-fluid p-0 pb-5 mb-5">
      <Carousel fade>
        <Carousel.Item style={{ minHeight: "300px" }}>
          <img
            className="d-block w-100"
            src="img/carousel-1.jpg"
            alt="First slide"
            style={{ minHeight: "300px", objectFit: "cover" }}
          />
          <Carousel.Caption>
            <div
              className="p-5"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "150vh",
              }}
            >
              <h5 className="text-white text-uppercase mb-md-3 text-center ">
                رسالتنا
              </h5>
              <h1 className="display-3 text-white mb-md-4 text-center">
                نشر العلم والتعليم -احترام المعلم
              </h1>
              <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="#"
                className="bp btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
              >
                المزيد
              </a>
            </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item style={{ minHeight: "300px" }}>
          <img
            className="d-block w-100"
            src="img/carousel-2.jpg"
            alt="Second slide"
            style={{ minHeight: "300px", objectFit: "cover" }}
          />
          <Carousel.Caption>
            <div
              className="p-5"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "150vh",
              }}
            >
              <h5 className="text-white text-uppercase mb-md-3 text-center">
                رسالتنا
              </h5>
              <h1 className="display-3 text-white mb-md-4 text-center">
                تقدير قيمة العلم - رعاية المواهب
              </h1>
              <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="#"
                className="bp btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
              >
                المزيد
              </a>
            </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item style={{ minHeight: "300px" }}>
          <img
            className="d-block w-100"
            src="img/carousel-3.jpg"
            alt="Third slide"
            style={{ minHeight: "300px", objectFit: "cover" }}
          />
          <Carousel.Caption>
            <div
              className="p-5"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "150vh",
              }}
            >
              <h5 className="text-white text-uppercase mb-md-3 text-center">
                رسالتنا
              </h5>
              <h1 className="display-3 text-white mb-md-4 text-center">
                تخريج جيل متعلم مثقف يرقى بالوطن - تقدير المتفوقين
              </h1>
              <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                
              }}
            >
              <a
                href="#"
                className="bp btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
              >
                المزيد
              </a>
            </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
