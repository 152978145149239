import React, { useEffect, useState } from 'react';
// import elmotaheda from '../assets/elmotaheda.jpg'
// import haz from '../assets/haz.jpg'
// import mo from '../assets/mo.jpg'
// import Ahmed from '../assets/Ahmed.jpg'


const Team = () => {
    const [teachers, setTeachers] = useState([]);

    // Fetching teachers data from the API
    useEffect(() => {
        fetch('https://yousab-tech.com/academy/public/api/teachers')
            .then((response) => response.json())
            .then((data) => {
                setTeachers(data.data.teachers); 
            })
            .catch((error) => {
                console.error('Error fetching teachers:', error);
            });
    }, []);

    return (
        <div className="container-fluid py-5">
            <div className="container pt-5 pb-3">
                <div className="text-center mb-5">
                    <h5 className="text-primary text-uppercase mb-3" style={{ letterSpacing: "5px" }}>المعلمين</h5>
                    <h1>تعرف على معلمينا</h1>
                </div>

                <div className="row">
                    {teachers.map((teacher) => (
                        <div className="col-md-6 col-lg-3 text-center team mb-4" key={teacher.id}>
                            <div className="team-item rounded overflow-hidden mb-2">
                                <div className="team-img position-relative">
                                    <img className="img-fluid" src={teacher.image} alt={teacher.firstname} alt={teacher.lastname} />
                                    <div className="team-social">
                                        <a className="btn btn-outline-light btn-square mx-1" href={teacher.twitter}><i className="fab fa-twitter"></i></a>
                                        <a className="btn btn-outline-light btn-square mx-1" href={teacher.facebook}><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-outline-light btn-square mx-1" href={teacher.linkedin}><i className="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                                <div className="bg-secondary p-4">
                                    <h5>{teacher.firstname} {teacher.lastname}</h5>
                                    <p className="m-0">{teacher.course}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;
