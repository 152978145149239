import React from "react";
import Team from "../components/Team";
import Breadcrump from "../components/Breadcrump";
const TheTeachers = () => {
  return (
    <div className="theTeachers-section">
      <Breadcrump title={'المعلمين'}/>
      <Team />
    </div>
  );
};
export default TheTeachers;
