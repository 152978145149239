import React from "react";
import Secondary from "../components/Secondary";
import Breadcrump from "../components/Breadcrump";
const SecondarySchool = () => {
  return (
    <div className="Secondary">
      <Breadcrump title={'المعلمين'}/>
      <Secondary />
    </div>
  );
};
export default SecondarySchool;
