import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Footer() {
  const [formData, setFormData] = useState({
    newsletterEmail: ''
  });

  const [errors, setErrors] = useState({
    newsletterEmail: ''
  });

  const handleChange = (e) => {
    console.log( e.target);
    
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation before API call
    if (!formData.newsletterEmail) {
      setErrors({
        newsletterEmail: !formData.newsletterEmail ? 'Email is required' : '',
       
      });
    }

    try {
      await axios.post('https://yousab-tech.com/academy/public/api/newsletter', formData);
      toast.success('Message sent successfully!');
      setFormData({
        newsletterEmail: ''
      });
      setErrors({
        newsletterEmail: ''
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.data) {
        const apiErrors = error.response.data.data;
        setErrors({
          newsletterEmail: apiErrors.newsletterEmail ? apiErrors.newsletterEmail.join('\n') : '',

        });
        toast.error('Error sending message. Please check your inputs.');
      } else {
        toast.error('Error sending message. Please try again later.');
      }
    }
  };

  const handleNewsletterSubmit = () => {
    if (!formData.newsletterEmail) {
      setErrors({ ...errors, newsletterEmail: 'Email is required for newsletter' });
      toast.error('Please enter a valid email.');
      return;
    }


  };

  return (
    <>
      <div className="container-fluid bg-dark text-white py-5 px-sm-3 px-lg-5" style={{ marginTop: "90px" }}>
        <div className="row pt-5">
          <div className="col-lg-7 col-md-12">
            <div className="row">
              <div className="col-md-6 mb-5">
                <h5 className="text-primary text-uppercase mb-4 text-right" style={{ letterSpacing: "5px" }}>تواصل معنا</h5>
                <p className="text-right"><i className="fa fa-map-marker-alt ml-2"></i>عمارة بنك مصر -الدور الثاني - طريق مصر أسيوط السريع</p>
                <p className="text-right"><i className="fa fa-phone-alt ml-2"></i>+01128181829</p>
                <p className="text-right"><i className="fa fa-envelope ml-2"></i>azab.group@yahoo.com</p>
                <div className="d-flex justify-content-start mt-4">
                  <a className="btn btn-outline-light btn-square mx-2" href="#"><i className="fab fa-twitter"></i></a>
                  <a className="btn btn-outline-light btn-square mx-2" href="#"><i className="fab fa-facebook-f"></i></a>
                  <a className="btn btn-outline-light btn-square mx-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                  <a className="btn btn-outline-light btn-square" href="#"><i className="fab fa-instagram"></i></a>
                </div>
              </div>
              <div className="col-md-6 mb-5 text-right">
                <h5 className="text-primary text-uppercase mb-4" style={{ letterSpacing: "5px" }}>دوراتنا</h5>
                <div className="d-flex flex-column">
                  <a className="text-white mb-2" href="#"><i className="fa fa-angle-left mx-2"></i>اللغة العربية</a>
                  <a className="text-white mb-2" href="#"><i className="fa fa-angle-left mx-2"></i>اللغة الانجليزية</a>
                  <a className="text-white mb-2" href="#"><i className="fa fa-angle-left mx-2"></i>اللغة الفرنسية</a>
                  <a className="text-white" href="#"><i className="fa fa-angle-left mx-2"></i>الرياضيات</a>
                  <a className="text-white mb-2" href="#"><i className="fa fa-angle-left mx-2"></i>العلوم</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 mb-5">
            <h5 className="text-primary text-uppercase mb-4" style={{ letterSpacing: "5px" }}>النشرة الإخبارية</h5>
            <p>اشترك في نشرتنا الإخبارية ليصلك كل جديد عن الأكاديميةالمتحدة التعليمية</p>
            <div className="w-100">
            <form id="newsletterForm" onSubmit={handleSubmit}>
                <div className="input-group">
                  <input 
                    type="email" 
                    className="form-control border-0 p-4" 
                    id="newsletterEmail" 
                    name="newsletterEmail"
                    placeholder="البريد الإلكتروني" 
                    value={formData.newsletterEmail}
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger" style={{ whiteSpace: 'pre-line' }}>{errors.email}</p>
                  <div className="input-group-append">
                    <button onClick={handleNewsletterSubmit} className="btn btn-primary px-4">اشتراك</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-white border-top py-4 px-sm-3 px-md-5" style={{ borderColor: "rgba(256, 256, 256, .1)" }}>
        <div className="row">
          <div className="col-lg-6 text-md-left mb-3 mb-md-0">
            <p className="m-0 text-white">&copy;. All Lefts Reserved. Designed by <a href="#">El Motahda</a></p>
          </div>
          <div className="col-lg-6 text-md-left">
            <ul className="nav d-inline-flex">
              <li className="nav-item"><a className="nav-link text-white py-0" href="#">Privacy</a></li>
              <li className="nav-item"><a className="nav-link text-white py-0" href="#">Terms</a></li>
              <li className="nav-item"><a className="nav-link text-white py-0" href="#">FAQs</a></li>
              <li className="nav-item"><a className="nav-link text-white py-0" href="#">Help</a></li>
            </ul>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Footer;
