import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Courses = () => {
    const [courses, setCourses] = useState([]);

    // Fetching courses data from the API
    useEffect(() => {
        fetch('https://yousab-tech.com/academy/public/api/courses')
            .then((response) => response.json())
            .then((data) => {
                setCourses(data.data.courses);  // Assuming the courses are inside data.data.courses
            })
            .catch((error) => {
                console.error('Error fetching courses:', error);
            });
    }, []);

    return (
        <div className="container-fluid py-5">
            <div className="container py-5">
                <div className="text-center mb-5">
                    <h5 className="text-primary text-uppercase mb-3" style={{ letterSpacing: '5px' }}>Courses</h5>
                    <h1>Available Courses</h1>
                </div>
                <div className="row">
                    {courses.map((course) => (
                        <div className="col-lg-4 col-md-6 mb-4" key={course.id}>
                            <div className="rounded overflow-hidden mb-2">
                                <img className="img-fluid" src={course.image} alt={course.title} />
                                <div className="bg-secondary p-4">
                                    <div className="d-flex justify-content-between mb-3">
                                        <small className="m-0"><i className="fa fa-users text-primary mr-2"></i>25 Students</small>
                                        <small className="m-0"><i className="far fa-clock text-primary mr-2"></i>01h 30m</small>
                                    </div>
                                    <a className="h5" href="#">{course.title}</a>
                                    <p dangerouslySetInnerHTML={{ __html: course.description }}></p>
                                    <div className="border-top mt-4 pt-4">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="m-0"><i className="fa fa-star text-primary mr-2"></i>4.5 <small>(250)</small></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Courses;
