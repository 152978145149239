// import logo from './logo.svg';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

// import Btn from "./Btn";
import { Routes } from "react-router-dom";
import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
// import { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer.js";
import Homepage from "./pages/Homepage.js";
import About from "./pages/About.js";
import TheCourses from "./pages/TheCourses.js";
import Contact from "./pages/Contact.js";
import Blogs from "./pages/Blogs.js";
import Teachers from "./pages/Teachers.js";
import SecondorySchool from "./pages/SecondorySchool.js";   

function App() {
  return (
    <div className="App">
      <Header />
       <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<TheCourses  />} />
        <Route path="/teachers" element={<Teachers />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/secondary" element={<SecondorySchool />} />
       </Routes>
      <Footer />
    </div>
  );
}
export default App;
