import React from 'react';
const Category = () => {
    return (
    <div className="container-fluid py-5">
    <div className="container pt-5 pb-3">
        <div className="text-center mb-5">
            <h5 className="text-primary text-uppercase mb-3" style={{ letterSpacing: '5px' }}>الدورات</h5>
            <h1>المواد الدراسية</h1>
        </div>
        <div className="row">
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-1.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">اللغة العربية</h4>
                        <span>أستاذ / محمد حظ</span>
                    </a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-2.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">اللغة الانجليزية</h4>
                        <span>أستاذ / مدحت خضر</span>
                    </a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-3.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">اللغة الفرنسية</h4>
                        <span>أستاذ / محمد نعيم</span>
                    </a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-4.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">العلوم</h4>
                        <span>أستاذ / محمد السقاري</span>
                    </a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-5.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">الرياضيات</h4>
                        <span>أحمد حنفي مجاور</span>
                    </a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-6.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">الرياضيات</h4>
                        <span>أستاذ / حامد الشيمي</span>
                    </a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-7.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">الخط</h4>
                        <span>دورات في  </span>
                    </a>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="cat-item position-relative overflow-hidden rounded mb-2">
                    <img className="img-fluid" src="img/cat-8.jpg" alt=""/>
                    <a className="cat-overlay text-white text-decoration-none" href="">
                        <h4 className="text-white font-weight-medium">القدرات لطلاب الثانوية العامة</h4>
                        <span>وتقدم دورة</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
);
}
export default Category;