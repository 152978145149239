import React from "react";
import BlogSection from "../components/BlogSection";


const Blogs = () => {
  return(
    <div className="home-section">
    </div>
  );
}
export default Blogs;
