import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        "https://yousab-tech.com/academy/public/api/message",
        formData
      );
      toast.success("Message sent successfully!");
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      });
      setErrors({
        name: "",
        email: "",
        subject: "",
        message: "",
        phone: "",
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.data) {
        const apiErrors = error.response.data.data;
        setErrors({
          name: apiErrors.name ? apiErrors.name.join("\n") : "",
          email: apiErrors.email ? apiErrors.email.join("\n") : "",
          subject: apiErrors.subject ? apiErrors.subject.join("\n") : "",
          message: apiErrors.message ? apiErrors.message.join("\n") : "",
          phone: apiErrors.phone ? apiErrors.phone.join("\n") : "",
        });
        toast.error("Error sending message. Please check your inputs.");
      } else {
        toast.error("Error sending message. Please try again later.");
      }
    }
  };

  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <div className="text-center mb-5">
          <h5
            className="text-primary text-uppercase mb-3"
            style={{ letterSpacing: "5px" }}
          >
            اتصال
          </h5>
          <h1>الاتصال للحصول على أي استفسار</h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="contact-form bg-secondary rounded p-5">
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="control-group mb-3">
                  <input
                    type="text"
                    className="form-control border-0 p-4"
                    id="name"
                    
                    placeholder="الاسم"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <p
                    className="help-block text-danger"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {errors.name}
                  </p>
                </div>
                <div className="control-group mb-3">
                  <input
                    type="email"
                    className="form-control border-0 p-4"
                    id="email"
                    placeholder="البريد الإلكتروني"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <p
                    className="help-block text-danger"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {errors.email}
                  </p>
                </div>
                <div className="control-group mb-3">
                  <input
                    type="text"
                    className="form-control border-0 p-4"
                    id="phone"
                    placeholder="رقم الهاتف"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <p
                    className="help-block text-danger"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {errors.phone}
                  </p>
                </div>
                <div className="control-group mb-3">
                  <input
                    type="text"
                    className="form-control border-0 p-4"
                    id="subject"
                    placeholder="موضوع"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  <p
                    className="help-block text-danger"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {errors.subject}
                  </p>
                </div>
                <div className="control-group mb-3">
                  <textarea
                    className="form-control border-0 py-3 px-4"
                    rows="5"
                    id="message"
                    placeholder="رسالة"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <p
                    className="help-block text-danger"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {errors.message}
                  </p>
                </div>
                <div className="text-center">
                  <button className="btn btn-primary py-3 px-5" type="submit">
                    أرسل رسالة
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ContactUs;
