import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Registration = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        result: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        phone: '',
        result: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://yousab-tech.com/academy/public/api/schoolership', formData);
            toast.success('Registration successful!');
            setFormData({
                name: '',
                email: '',  
                phone: '',
                result: ''
            });
            setErrors({
                name: '',
                email: '',
                phone: '',
                result: ''
            });
        } catch (error) {
            if (error.response && error.response.data) {
                console.log("API Error Response:", error.response.data); // Debugging

                const apiErrors = error.response.data.data;
                setErrors({
                    name: apiErrors.name ? apiErrors.name.join('\n') : '',
                    email: apiErrors.email ? apiErrors.email.join('\n') : '',
                    phone: apiErrors.phone ? apiErrors.phone.join('\n') : '',
                    result: apiErrors.result ? apiErrors.result.join('\n') : ''
                });

                toast.error('Error sending registration. Please check your inputs.');
            } else {
                toast.error('Error sending registration. Please try again later.');
            }
        }
    };

    return (
        <div className="container-fluid bg-registration py-5" style={{ margin: "90px 0" }}>
            <div className="container py-5">
                <div className="row align-items-center">
                    <div className="col-lg-7 mb-5 mb-lg-0">
                        <div className="mb-4 text-right">
                            <h5 className="text-primary text-uppercase mb-3" style={{ letterSpacing: "5px" }}>
                                بحاجة إلى أي دورات
                            </h5>
                            <h1 className="text-white">خصم 30% للطلاب الجدد</h1>
                        </div>
                        <p className="text-white">
                            دورات فى مادة (اللغة العربية واللغة الانجليزية واللغة الفرنسية والعلوم والرياضيات)
                        </p>
                        <ul className="list-inline text-white m-0">
                            <li className="py-2 text-right">
                                <i className="fa fa-check text-primary mr-3"></i>دورات عامة فى مختلف المجالات (تحسين الخط - حساب ذهنى - حاسب آلى برمجة)
                            </li>
                            <li className="py-2 text-right">
                                <i className="fa fa-check text-primary mr-3"></i>تعديل سلوك واضطرابات تعلم.
                            </li>
                            <li className="py-2 text-right">
                                <i className="fa fa-check text-primary mr-3"></i>دورات تأهيل لأولياء الأمور للتعامل مع أبنائهم.
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-5">
                        <div className="card border-0">
                            <div className="card-header bg-light text-center p-4">
                                <h1 className="m-0">سجل الآن</h1>
                            </div>
                            <div className="card-body rounded-bottom bg-primary p-5">
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control border-0 p-4"
                                            id="name"
                                            placeholder="الاسم"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                        <p className="help-block text-danger" style={{ whiteSpace: 'pre-line' }}>{errors.name}</p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input
                                            type="email"
                                            className="form-control border-0 p-4"
                                            id="email"
                                            placeholder="البريد الإلكتروني"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        <p className="help-block text-danger" style={{ whiteSpace: 'pre-line' }}>{errors.email}</p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control border-0 p-4"
                                            id="phone"
                                            placeholder="رقم الهاتف"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                        <p className="help-block text-danger" style={{ whiteSpace: 'pre-line' }}>{errors.phone}</p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input
                                            type="number"
                                            className="form-control border-0 p-4"
                                            id="result"
                                            placeholder="النتيجة"
                                            value={formData.result}
                                            onChange={handleChange}
                                        />
                                        <p className="help-block text-danger" style={{ whiteSpace: 'pre-line' }}>{errors.result}</p>
                                    </div>
                                    <div>
                                        <button
                                            className="btn btn-dark btn-block border-0 py-3"
                                            type="submit"
                                        >
                                            سجل الآن
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
};

export default Registration;
