import React from "react";
const AboutUs = () => {
  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              src="img/about.jpg"
              alt=""
            />
          </div>
          <div className="col-lg-7">
            <div className="text-right mb-4">
              <h5
                className="text-primary text-uppercase mb-3"
                style={{ letterSpacing: "5px" }}
              >
                <br /> من نكون ؟ <br />

                نحن أبناء مركز العياط نؤمن بفكرة أن تقدم المجتمع يقوم على
                الاهتمام بالتعليم ,الذي يؤدى بدوره الي رقي المجتمع والحفاظ علي
                مستقبل أبنائنا
              </h5>
              <h1 className= "p-5"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}>هدفنا اجتماعي</h1>
            </div>
            <p>
              وهو خلق بيئة تعليمية متكاملة أركانها الطالب والمعلم والمناهج
              البناءة والبيئة المناسبة لكي نحافظ علي مستقبل أبنائنا
            </p>
            <a
              href=""
              class="btn btn-primary py-md-2 px-md-4 font-weight-semi-bold mt-2"
            >
              المزيد
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
