import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Testimonial = () => {
    const [testimonials, setTestimonials] = useState([]);

    // Fetching testimonials data from the API
    useEffect(() => {
        fetch('https://yousab-tech.com/academy/public/api/testimonials')
            .then((response) => response.json())
            .then((data) => {
                setTestimonials(data.data.testimonials);  // Assuming the data structure is correct
            })
            .catch((error) => {
                console.error('Error fetching testimonials:', error);
            });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div className="container-fluid py-5">
            <div className="container py-5">
                <div className="text-center mb-5">
                    <h5 className="text-primary text-uppercase mb-3" style={{ letterSpacing: "5px" }}>شهادة</h5>
                    <h1>ماذا يقول طلابنا</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <Slider {...settings} className="testimonial-carousel">
                            {testimonials.map((testimonial) => (
                                <div key={testimonial.id} className="text-center">
                                    <i className="fa fa-3x fa-quote-left text-primary mb-4"></i>
                                    <h4 className="font-weight-normal mb-4">{testimonial.description}</h4>
                                    <img className="img-fluid mx-auto mb-3" src={testimonial.image} alt={testimonial.title} />
                                    <h5 className="m-0">{testimonial.subtitle}</h5>
                                    <span>{testimonial.clientRole}</span>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;

