function Breadcrump(props)
{
    return(
        <div className="container-fluid page-header" style={{marginBottom :"90px"}}>
            <div className="container">
                <div className="d-flex flex-column justify-content-center" style={{minHeight:"300px"}}>
                    <h3 className="display-4 text-white text-uppercase">{props.title}</h3>
                    <div className="text-white d-flex flex-row justify-content-center">
                        <p className="m-0 text-uppercase"><a className="text-white" href="">الرئيسية</a></p>
                        <i className="fa fa-angle-double-left pt-1 px-3"></i>
                        <p className="m-0 text-uppercase text-primary">{props.title}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Breadcrump;