import React from "react";
import About from "../components/AboutUs";
import Carousel from "../components/Carousel";
import Category from "../components/Category";
import Courses from "../components/Courses";
import Team from "../components/Team";
import Registration from "../components/Registration";
import Testimonial from "../components/Testimonial";
// import Blog from "../components/BlogSection";

const Homepage = () => {
  return(
    <div className="home-section">
      <Carousel />
      <About />
      <Category />
      <Courses />
      <Registration />
      <Team />
      {/* <Blog  /> */}
      <Testimonial />
    </div>
  );
};
export default Homepage;
